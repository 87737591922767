import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import React, { useEffect, useState } from "react";
import routes from "../constants/routes";
import { useTranslation } from "react-i18next";
import NavDropdown from "react-bootstrap/NavDropdown";

const Footer = () => {
  const [pathName, setPathName] = useState();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const hideFooter = location?.pathname.includes("rsp-terms");

  useEffect(() => {
    setPathName(location?.pathname);
  }, [location?.pathname]);

  if (hideFooter) {
    return null;
  }
  
  return (
    <>
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bottom-footer">
                <ul className="justify-content-between">
                  <li
                    className={
                      pathName === routes.News
                        ? "nav-item active-link"
                        : "nav-item "
                    }
                  >
                    <Link className={"nav-link btm-border"} to={routes.News}>
                      {t("News")}
                    </Link>
                  </li>
                  <li
                    className={
                      pathName === routes.Careers
                        ? "nav-item active-link"
                        : "nav-item "
                    }
                  >
                    <Link className={"nav-link btm-border"} to={routes.Careers}>
                      {t("Careers")}
                    </Link>
                  </li>
                  <li
                    className={
                      pathName === routes.PrivacyCookiePolicy
                        ? "nav-item active-link"
                        : "nav-item "
                    }
                  >
                    <Link
                      className={"nav-link btm-border"}
                      to={routes.PrivacyCookiePolicy}
                    >
                      {t("CookiePolicy")}
                    </Link>
                  </li>
                  {/* <li
                    className={
                      pathName === routes.factorings
                        ? "nav-item active-link"
                        : "nav-item "
                    }
                  >
                    <Link className={"nav-link btm-border"}>
                      {t("FactoringPrivacy")}
                    </Link>
                  </li> */}

                  <li
                    className={
                      pathName === routes.LegalNotice
                        ? "nav-item active-link"
                        : "nav-item "
                    }
                  >
                    {/* <Link
                      className={"nav-link btm-border"}
                      to={routes.LegalNotice}
                    >
                      Legal Documents
                    </Link> */}
                    <NavDropdown
                      title={t("Legaldocuments")}
                      id="basic-nav-dropdown"
                      className="footerDropDown"
                    >
                      <NavDropdown.Item href="/legal-notices">
                        {t("legalNoticeTitle")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/documentation">
                        Pillar Report
                      </NavDropdown.Item>
                    </NavDropdown>
                  </li>

                  <li>
                    <Link
                      className={"nav-link"}
                      to={"https://global.rakuten.com/corp/"}
                      target="_blank"
                    >
                      {t("RakutenGlobal")}
                    </Link>
                  </li>
                  <li
                    className={
                      pathName === routes.Contact
                        ? "nav-item active-link"
                        : "nav-item "
                    }
                  >
                    <Link className={"nav-link Noborder"} to={routes.Contact}>
                      {t("ContactUs")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
