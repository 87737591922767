import React from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

const Cookies = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="cookiesBoxSection">
        <CookieConsent
          location="bottom"
          buttonText={t("Cookiesclose")}
          buttonClasses="btn closebtn"
          cookieName="mycookies"
          expires={150}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-10">
                <div className="cookiesBox">
                  <p>
                    {t("Cookiesp")}

                    <strong>
                      <a href="/privacy-and-cookie-policy">
                        {t("Cookiespolicy")}
                      </a>
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </CookieConsent>
      </section>
      {/* <section className="cookiesBoxSection">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-10">
              <div className="cookiesBox">
                <p>
                  why we’re anything but average. SIGN ME UP To ensure an
                  enjoyable website experience, we use essential cookies. Don’t
                  worry, there’s no crumbs. Hungry for more information? Here’s
                  our <strong>Privacy and Cookie Policy.</strong>
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="cookiesBox">
                <button className="btn btn-secondary">CLOSE</button>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Cookies;
