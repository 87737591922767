import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Cookies from "../components/Cookies";
import AOS from "aos";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import "aos/dist/aos.css";
import "../styles/style.css";
import "../styles/stylecustom.css";
import "../styles/responsive.css";
import { withTrans } from "../i18n/withTrans";

const Layout = ({ children, t, i18n }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      {children}
      <Footer />
      <Cookies />
    </>
  );
};

export default withTrans(Layout);
