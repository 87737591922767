const routes = {
    home: "/",
    business: "/business/",
    About: "/about-us/",
    Esg: "/esg/",
    News: "/news/",
    Careers: "/careers",
    Contact: "/contact-us",
    LegalNotice: "/legal-notices",
    PrivacyCookiePolicy: "/privacy-and-cookie-policy",
    factorings: "/factoring",
    RspTerms:"/rsp-terms",
    documentation: "/documentation",
    info: "/info"

};
export default routes;
