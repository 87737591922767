exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-business-jsx": () => import("./../../../src/pages/business.jsx" /* webpackChunkName: "component---src-pages-business-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-documentation-jsx": () => import("./../../../src/pages/documentation.jsx" /* webpackChunkName: "component---src-pages-documentation-jsx" */),
  "component---src-pages-esg-jsx": () => import("./../../../src/pages/esg.jsx" /* webpackChunkName: "component---src-pages-esg-jsx" */),
  "component---src-pages-factoring-jsx": () => import("./../../../src/pages/factoring.jsx" /* webpackChunkName: "component---src-pages-factoring-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-info-jsx": () => import("./../../../src/pages/info.jsx" /* webpackChunkName: "component---src-pages-info-jsx" */),
  "component---src-pages-legal-notices-jsx": () => import("./../../../src/pages/legal-notices.jsx" /* webpackChunkName: "component---src-pages-legal-notices-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-privacy-and-cookie-policy-jsx": () => import("./../../../src/pages/privacy-and-cookie-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-and-cookie-policy-jsx" */),
  "component---src-pages-rsp-terms-jsx": () => import("./../../../src/pages/rsp-terms.jsx" /* webpackChunkName: "component---src-pages-rsp-terms-jsx" */)
}

